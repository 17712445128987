/* ==========================================================================
  Base "popup"
  ========================================================================== */

.popup {
  max-width: 20rem;
  margin: 0 auto 2rem;

  @media(min-width: $screen-md-min) {
    max-width: 60rem;
  }
}

.slider-popup {
  margin: 0 auto 4rem;

  @media(min-width: $screen-lg-min) {
    max-width: 62rem;
  }

  .popup {
    margin: 0 auto;
  }
}
